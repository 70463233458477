import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { SocialIcon } from "react-social-icons";

const SocialMediaTeaser: React.FC = () => {
  const { t } = useTranslation(["social_media"]);
  return (
    <div
      className="flex flex-col space-y-2 text-center"
      // data-sal="slide-up"
    >
      <h2 className="text-1.5xl md:text-2xl font-headline text-primary">
        {t("social_media:title")}
      </h2>
      <span className="text-base tracking-wide opacity-75 md:text-regular">
        {t("social_media:description1")}
        <br className="sm:hidden" />
        &nbsp;
        {t("social_media:description2")}
      </span>
      <div className="flex flex-row justify-center pt-2 space-x-2 md:space-x-6">
        <div className="transform scale-75 rounded-full text-primary md:scale-100">
          <SocialIcon
            target="_blank"
            rel="noopener"
            bgColor="white"
            fgColor="#334155"
            url="https://www.facebook.com/Plan3t-105893404557101"
          />
        </div>
        <div className="transform scale-75 rounded-full md:scale-100">
          <SocialIcon
            target="_blank"
            rel="noopener"
            bgColor="white"
            fgColor="#334155"
            url="https://www.linkedin.com/company/plan3t"
          />
        </div>
        <div className="transform scale-75 rounded-full md:scale-100">
          <SocialIcon
            target="_blank"
            rel="noopener"
            bgColor="white"
            fgColor="#334155"
            url="https://instagram.com/plan3t.app"
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMediaTeaser;
